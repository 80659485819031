<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <forexTabs />
    <b-card>
      <b-row
        align-v="center"
        class="mb-2 pb-1"
        style="border-bottom: 1px solid #ddd"
      >
        <b-col
          class="text-center"
          style="cursor: pointer"
          cols="auto"
          @click="$router.go(-1)"
        >
          <b-col cols="12">
            {{ $t("back") }}
          </b-col>
          <b-col cols="12">
            <feather-icon
              v-if="$i18n.isRTL"
              icon="ArrowRightIcon"
              size="14"
            />
            <feather-icon
              v-else
              icon="ArrowLeftIcon"
              size="14"
            />
          </b-col>
        </b-col>
      </b-row>
      <b-form class="mt-3">

        <b-row>
          <b-col sm="4">

            <b-form-group
              :label="$t('forex.source-account')"
              class="mb-1"
            >
              <v-select
                v-model="sourceAccSelected"
                :options="sourceAccInfo"
                :clearable="false"
                label="account"
              />
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group
              :label="$t('amount')"
              class="mb-1"
            >
              <b-form-input
                v-model="amount"
                :placeholder="$t('amount')"
                name="amount"
              />
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group
              :label="$t('forex.destination-account')"
              class="mb-1"
            >
              <v-select
                v-model="destinationAccSelected"
                :clearable="false"
                :options="destinationAccInfo"
                label="account"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="d-flex justify-content-center"
          >
            <b-button
              variant="primary"
              :loading="isCreateAccount"
              class="mt-1"
              @click="save"
            >
              <span v-if="isCreateAccount">
                <b-spinner
                  variant="light"
                  type="grow"
                  small
                />
              </span>
              <span v-else>
                {{ $t('forex.transfer') }}
              </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BForm,
  BFormInput,
  BOverlay,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import MetatraderService from '@/modules/forex/service/apis'
import forexTabs from './components/forexTabs.vue'

const metatrader = new MetatraderService()
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BSpinner,
    vSelect,
    BForm,
    BFormInput,
    BOverlay,
    forexTabs,
  },
  data() {
    return {
      amount: '',
      required,
      isCreateAccount: false,
      show: false,
      sourceAccSelected: {},
      destinationAccSelected: {},
      sourceAccInfo: [],
      destinationAccInfo: [],
    }
  },
  watch: {
    sourceAccSelected(val) {
      this.onchangeSourceAccount(val)
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.show = true
      await metatrader.getAccounts().then(res => {
        if (this.$route.params.id) {
          // eslint-disable-next-line eqeqeq
          const sourceAccInfo = res.data.results.find(item => item.account == this.$route.params.id)
          this.sourceAccSelected = sourceAccInfo
          // eslint-disable-next-line eqeqeq
        }
        this.sourceAccInfo = res.data.results
      }).catch(err => {
        console.log(err)
        this.show = false
      })
    },
    async save() {
      this.isCreateAccount = true
      const data = {
        platform: this.sourceAccSelected.platform,
        from_account: this.sourceAccSelected.account,
        to_account: this.destinationAccSelected.account,
        amount: parseFloat(this.amount, 10),
      }
      await metatrader.transfer(data).then(res => {
        this.$swal({
          title: res.data.message,
          icon: 'success',
          timer: 2500,
          showConfirmButton: false,
        })
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.isCreateAccount = false
      })
    },
    onchangeSourceAccount(item) {
      this.show = true
      this.destinationAccSelected = {}
      metatrader.getAccounts({ platform: item.platform }).then(res => {
        // eslint-disable-next-line no-shadow
        this.destinationAccInfo = res.data.results.filter(acc => acc.account !== this.sourceAccSelected.account)
        this.show = false
      }).catch(err => {
        console.log(err)
      })
    },
  },

}
</script>
